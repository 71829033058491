.table thead th.right, .table tr td.right {
    text-align: right;
}

.table th {
    font-weight: normal;
    text-transform: uppercase;
    font-size: var(--font-size-s);
    border-top: 0;
    color: var(--kanban-col-title-color);
    background:var(--col-content-bg);

}

.table th.centeralign, .table td.centeralign {
    text-align: center;
}


.table.table-bordered th {
    border-color: var(--neutral);
}

.table .con0 {
}

.table .con1 {
}

.table tr.even {
    background:var(--col-row-striped);
}

.table-bordered th,
.table-bordered td {
    border-right: 0px solid var(--neutral);
    border-left:0px;
    box-shadow: 0px 1px 1px var(--neutral);
}

.table-bordered th:last-child,
.table-bordered td:last-child {
    border-right: 0px solid var(--neutral);
}


.dataTable {
    margin-bottom: 0;
}

.dataTable th,
.dataTable td {
    font-size: var(--base-font-size);
}

.dataTables_wrapper {
    position: relative;
    padding-bottom: 150px;
}

.simpleAccordionContainer .dataTables_wrapper{
    padding-bottom:0px
}

.dataTables_length, .dataTables_info {
    padding: 10px;
    font-size: var(--font-size-s);
    border: 0px solid var(--neutral);
}

.dataTables_length {
    border-bottom: 0;
    padding-bottom: 20px;
}

.dataTables_info {
    border-top: 0;
}

.dataTables_filter {
    position: absolute;
    margin-top: -45px;
    right: 10px;
}

.dataTables_filter input {
    width: 150px;
    margin: 0 0 0 10px;
}

.dataTables_paginate {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.dataTables_paginate .first, .dataTables_paginate .previous, .dataTables_paginate .paginate_active,
.dataTables_paginate .paginate_button, .dataTables_paginate .next, .dataTables_paginate .last {
    padding: 5px 10px;
    border: 0px solid var(--neutral);
    font-size: var(--font-size-s);
    cursor: pointer;
}

.dataTables_paginate span:hover {
    background-color: var(--secondary-action-hover-bg);
}

.dataTables_paginate .first {
    border-left: 0px solid var(--neutral);
}

.dataTables_paginate .paginate_active {
    background: var(--primary-color);
}

.dataTables_paginate .paginate_button:hover {
    background: var(--kanban-col-title-bg);
}

.dataTables_paginate .paginate_button_disabled {
    cursor: default;
    color: var(--neutral);
    background: var(--kanban-col-title-bg);
}

.dataTables_paginate a {
    color: var(--primary-font-color);
    font-size:var(--font-size-s);
}

.dataTables_paginate a:hover {
    text-decoration: none;
}

.dataTables_length select {
    width: auto !important;
    margin: 0;
}

.dataTables_scrollHead {
    background: var(--primary-font-color);
}

.dataTables_scrollHead table {
    border-bottom: 0;
}

.dataTable thead th.sorting,
.dataTable thead th.sorting_asc,
.dataTable thead th.sorting_desc {
    cursor: pointer;

}

.dataTable thead th.sorting:hover,
.dataTable thead th.sorting_asc:hover,
.dataTable thead th.sorting_desc:hover {
    background: var(--dropdown-link-hover-bg);
    color:var(--dropdown-link-hover-color);

}

.dataTable thead th.sorting:after {
    font-family: FontAwesome;
    content: "\00a0\00a0\f0dc";
    font-size:var(--font-size-s);
}

.dataTable thead th.sorting_asc:after {
    font-family: FontAwesome;
    content: "\00a0\00a0\f0de";

}

.dataTable thead th.sorting_desc:after {
    font-family: FontAwesome;
    content: "\00a0\00a0\f0dd";
}

.maincontentinner .table,
.maincontentinner table{
    border:1px solid var(--col-content-bg);

}

.table-bordered {
    border-top-left-radius:var(--box-radius);
    border-top-right-radius:var(--box-radius);
}

.maincontentinner .table th,
.maincontentinner table th,
.maincontentinner table thead td,
.maincontentinner .table.table-bordered th,
.maincontentinner .table tr.dtrg-group td{
    font-weight: normal;
    text-transform:none;
    font-size: var(--base-font-size);
    border-top: 0;

    font-weight: normal;
    text-rendering: optimizelegibility;
    padding: 7px 10px 5px 10px;

    border:0px;
    border-right:0px solid var(--neutral);
    border-color:var(--neutral);
    line-height: 17px;

    color: var(--kanban-col-title-color);
    background:var(--secondary-background);
}

.maincontentinner .table th,
.maincontentinner table thead td,
.maincontentinner .table.table-bordered th,
.maincontentinner table th {
    background:var(--col-title-bg);
    padding: 10px 10px;
    font-weight:bold;
    transition: all 0.2s ease-in-out 0s;
}

.table-bordered colgroup+thead tr:first-child th:first-child {
    border-top-left-radius:var(--box-radius);
}

.table-bordered colgroup+thead tr:last-child th:last-child {
    border-top-right-radius:var(--box-radius);
}

.maincontentinner .table th:last-child,
.maincontentinner .table.table-bordered th:last-child {
    border-right:0px;
}


.maincontentinner .table th.sorting_desc,
.maincontentinner .table th.sorting_asc {
}


.maincontentinner .table tr.dtrg-group td {
    color: var(--kanban-col-title-color);
    padding: 5px 10px;
    padding-top:25px;
    font-weight: bold;
}

.maincontentinner .table {
    margin-top:10px;
}

.maincontentinner .table td,
.maincontentinner table td{
    padding:5px 10px 3px 10px;
    vertical-align: middle;
    min-height:17px;
    border-right:1px solid var(--secondary-background);
}

.maincontentinner .listStyleTable td {
    border-right:0px;
}

.maincontentinner table.fc-scrollgrid td,
.maincontentinner table.fc-scrollgrid th{
    padding:0px;
    background: var(--kanban-card-bg);
}

.maincontentinner table.fc-scrollgrid th.fc-col-header-cell {
    background: var(--col-title-bg);
}

.maincontentinner table.fc-scrollgrid th.fc-col-header-cell:first-child {
    border-top-left-radius:var(--box-radius);
}

.maincontentinner table.fc-scrollgrid th.fc-col-header-cell:last-child {
    border-top-right-radius:var(--box-radius);
}


.maincontentinner table.fc-scrollgrid th {
    padding:0px;
    border-radius: 5px 5px 0px 0px;
}

.maincontentinner table.fc-scrollgrid th .fc-scrollgrid-sync-inner {
    padding:5px 0px;
}

.dataTable tr:hover {
    box-shadow:var(--regular-shadow);
}

.dataTable tr:hover td {
    background:var(--col-title-bg);

}

.maincontentinner .table td .small-input {
    width:40px;
}

.maincontentinner .dataTables_wrapper  .bottom {
    position:relative;
    border: 0px solid var(--main-border-color);
    border-top:0px;
    height:30px;
}

.maincontentinner .dataTables_wrapper  .dataTables_info {
    position:absolute;
    left:0px;
    top:10px;
    border:0px;
    font-size:12px;
}

.maincontentinner .dataTables_wrapper  .dataTables_length{
    position:absolute;
    left:50%;
    top:10px;
    margin-left:-65px;
    border:0px;
    padding:5px;
    font-size:var(--font-size-s);
}

.maincontentinner .dataTables_wrapper  .dataTables_length label,
.maincontentinner .dataTables_wrapper  .dataTables_length select {
    font-size:var(--font-size-s);
}

.maincontentinner .dataTables_wrapper .dataTables_paginate{
    position:absolute;
    right:0px;
    top:10px;
    border:0px;
    padding:10px;
    font-size:12px;
}

.maincontentinner .table tr.dt-rowReorder-moving {
    outline:2px solid var(--neutral);
}

.paginate_button.current {
    color:#fff;
}

.maincontentinner .dt-buttons .dt-button-collection {
    position:absolute;
    z-index:3;
    float: left;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    background-color: var(--secondary-background);
    margin-top: 10px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: 0px;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

.maincontentinner .dt-buttons .dt-button-collection button {
    display:block;
    width:100%;
    margin:0px;
    background:var(--secondary-background);
    color: var(--main-menu-link-color);
    padding: 5px 10px;
    border:0px;
    text-align:left;
}

.maincontentinner .dt-buttons .dt-button-collection button:before {
    font-family: "Font Awesome 6 Free";
    content: "\f204";
    margin-right:5px;
}

.maincontentinner .dt-buttons .dt-button-collection button. dt-button-active{
    text-decoration: none;
}

.maincontentinner .dt-buttons .dt-button-collection button.dt-button-active:before {
    font-family: "Font Awesome 6 Free";
    content: "\f205";
    color:var(--accent1);
}

.maincontentinner .dt-buttons .dt-button-collection button:hover,
.maincontentinner .dt-buttons .dt-button-collection button.active:hover{
    color: var(--dropdown-link-hover-color);
    background:var(--dropdown-link-hover-bg);
}

.maincontentinner .dt-buttons>.dt-button {
    display: inline-block;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: var(--neutral);
    border: 1px solid var(--secondary-action-bg);
    margin: 0 0 9px;
    padding: 4px 14px;
    line-height: 21px;
    text-align: center;
    vertical-align: baseline;
    cursor: pointer;
    color: var(--secondary-action-color);
    background: var(--secondary-action-bg);
    text-shadow: none;
}

.maincontentinner .dt-buttons>.dt-button span {
}

.maincontentinner .dt-buttons>.dt-button:hover {
    background:var(--secondary-action-hover-bg);
    color:var(--secondary-action-hover-color);
}
.maincontentinner .dt-buttons .dt-button-collection button {
    border-radius:0px;
}

.maincontentinner .dt-buttons .dt-button-collection button:hover {
    background:var(--main-menu-link-hover-bg);
    color:var(--main-menu-link-hover-color);
}


.timesheetTable td.active,
.timesheetTable th.active{
   background:var(--col-title-bg);
}

.maincontentinner .timesheetTable td input {
    margin-bottom:5px;
}

.maincontentinner .timesheetTable tfoot td {
    padding:10px;
}

.maincontentinner .headtitle table,
.maincontentinner .headtitle .table th,
.maincontentinner .headtitle .table td{
    border:none;
    margin-top:0px;
}

.table th,
.table td,
table td{
    border-top:1px solid var(--col-title-bg);
}


.articleBody table thead td{
    background:var(--neutral);
}

.articleBody table td {
    border:1px solid var(--neutral);
}

/*fake height to allow divs in tds to fill out space */
.table tr {
    height:1px;
}

.table td.dropdown-cell {
    padding:0px;
    font-weight: 400;
    height: inherit;
}

.table td.dropdown-cell .dropdown {
    width:100%;
    height:100%;
    border-radius:0px !important;
    display:block;

}

.table td.dropdown-cell .dropdown > a {
    width:100%;
    height:100%;
    border-radius:0px !important;
    padding: 5px 10px 3px 10px !important;
    line-height: 32px;
    font-size:var(--base-font-size);
    box-shadow:none;
    display:inline-block;
}

.table td.dropdown-cell .dropdown > a .text {
}
.table td.dropdown-cell .dropdown > a i {
    float:right;
    line-height:31px;
}

.table td.dropdown-cell .dropdown.ticketDropdown ul {
    top:35px;
}

.listStyleTable td {

}

.maincontentinner .table.listStyleTable {
    border:0px;
}

.maincontentinner .table.listStyleTable td {
    padding:5px 10px 5px 10px;
}

.maincontentinner .table.listStyleTable td.dropdown-cell {
    padding:0px;
}

.maincontentinner .table.listStyleTable td.dropdown-cell .dropdown > a {
    padding:0px 10px 0px 10px !important;

}

.maincontentinner .table.listStyleTable .dtrg-group td {
    border:0px;
}

.maincontentinner .table.listStyleTable tr:hover {
    box-shadow: none;
    cursor:pointer;
}

.maincontentinner .table.listStyleTable tr.active td {
    background: var(--col-title-bg);
}



.maincontentinner .table.listStyleTable td.roundStatusBtn .statusDropdown > a {
    border-radius: 15px;
    padding: 0px;
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    text-align: center;
}

.maincontentinner .dataTables_wrapper .center .dataTables_paginate {
    left: auto;
    right: auto;

    text-align: center;
    width: 100%;
}


.maincontentinner .table.listStyleTable td.roundStatusBtn .f-left {
    float:none !important;
}

.maincontentinner .table.listStyleTable .dtrg-start:first-child td{
    padding-top:0px
}

.maincontentinner .table tfoot td,
.maincontentinner table tfoot td {
    font-weight: bold;
    border-top: 1px solid var(--neutral);
    /* text-align: center; */
    /* min-height: 41px; */
    padding: 8px 5px 8px 20px;
}

.ticketTable .ticketModal {
    font-weight:bold;
}

.dt-button-down-arrow {
    font-size:0px;
}

.dt-button-down-arrow:before {
    font-family: "Font Awesome 6 Free";
    content: "\f0d7";
    font-size: var(--font-size-m);
    margin-left: 5px;
}

table.fc-multimonth-header-table {
    border:0px;
}

